import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Card from "./Card";


function Socialpage() {
  const textRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, delay: 0 },
    );
  }, []);

  return (
    <div ref={textRef} className="flex-grow flex flex-col items-center justify-center gap-y-4">
      <Card
        thumbnail={"/assets/icons/discordIcon.svg"}
        title= "Discord"
        description= "Join my discord server!"
        titleUrl={"https://discord.gg/VXbSXTC9xR"}
        thumbnailUrl={"https://discord.gg/VXbSXTC9xR"}
        thumbnailSize="w-16 h-16"
      />

      <Card
        thumbnail={"/assets/icons/gitHubIcon.svg"}
        title= {"Github"}
        titleUrl={"https://github.com/4v0n"}
        thumbnailUrl={"https://github.com/4v0n"}
        thumbnailSize="w-16 h-16"
      />

      <Card
        thumbnail={"/assets/icons/linkedinIcon.svg"}
        title= {"Linkedin"}
        titleUrl={"https://www.linkedin.com/in/aron-kumarawatta-78b70a267/"}
        thumbnailUrl={"https://www.linkedin.com/in/aron-kumarawatta-78b70a267/"}
        thumbnailSize="w-16 h-16"
      />

      <Card
        thumbnail={"/assets/icons/osuIcon.svg"}
        title= {"osu!"}
        description= {"I ran out of things to put on here..."}
        titleUrl={"https://osu.ppy.sh/users/17888305"}
        thumbnailUrl={"https://osu.ppy.sh/users/17888305"}
        thumbnailSize="w-16 h-16"
      />
    </div>
  );
}

export default Socialpage;